<template>
    <div>
      <div v-if="!isLoading">
          <ul v-if="typeof sources !== 'undefined' && sources.length" class="list-group">
              <li
                  v-for="source in sources"
                  :key="source.id"
                  class="list-group-item"
              >
                  {{source.title}}
              </li>
          </ul>
      </div>
      <div v-else>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters('source_groups', ['sources']),
        },
        methods: {
            loadSources() {
                this.isLoading = true;
                this.$store.dispatch('source_groups/getSources',{
                    filters: {
                        has_source_group: false,
                    },
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        mounted() {
            this.$store.dispatch('source_groups/clearSources');
            this.loadSources();
        }
    }
</script>
<style lang="scss">/*
    .card {
        min-width: 400px;
        min-height: 400px;
    }
    h3 {
        font-weight: 700;
    }
*/</style>
